<template>
  <div>
    <el-dialog
      width="340px"
      center
      title=""
      :visible.sync="visible"
      @closed="onClose"
    >
      <div class="view">
        <div class="topTittle">
          <img :src="viewRow.img">
          <div>{{viewRow.tittle}}</div>
        </div>

        <template v-if="viewRow.key=='key1'">
          <div class="content">
            <p class="gray"> 证书主要内容： </p>
            <p class="value">
              由于证书主要内容过长，不宜在窗口展示，您只需要点击下方复制核验内容按钮即可自动复制。
            </p>
          </div>
          <div class="tips">
            SHA加密网站得出来的结果与您拥有的证书内容完整性校验值一致，即没有问题
          </div>
          <div class="btnWrap">
            <el-button type="primary">前往SHA256加密网站对比</el-button>
          </div>
        </template>

        <template v-if="viewRow.key=='key2'">
          <div class="content">
            <p class="gray"> XX链存证编码： </p>
            <p class="value">
              uwqiuwioquisyqieioquoshah2191w1212sihquwhsuiqwhuiqwswqsqw
            </p>
          </div>
          <div class="btnWrap">
            <el-button type="primary">去XX互联网法院核验</el-button>
          </div>
        </template>

        <template v-if="viewRow.key=='key3'">
          <div class="content">
            <p class="gray"> 公示Hash-电子数据完整性校验值： </p>
            <p class="value">
              12xniw9unuwqiuwioquisyqieioquoshah2191w1212sihquwhsuiqw29shwqshi
            </p>
          </div>
          <div class="btnWrap">
            <el-button type="primary">去蚂蚁区块链核验</el-button>
          </div>
        </template>

        <template v-if="viewRow.key=='key4'">
          <div class="content">
            <p class="gray"> 数字证书持有人： </p>
            <p class="value">
              查雪琳
            </p>
            <p class="gray mt8"> 证书签发机构： </p>
            <p class="value">
              XXXXXX电子商务服务有限公司
            </p>
          </div>
          <div class="btnWrap">
            <el-button type="primary">去XXXX官网核验</el-button>
          </div>
        </template>

        <template v-if="viewRow.key=='key5'">
          <div class="content">
            <p class="gray"> 证书授时时间： </p>
            <p class="value">
              2020-12-25 10:33:59
            </p>
            <p class="gray mt8"> 授时凭证编号： </p>
            <p class="value">
              TTAS_S.0.2_127901730971209370912793711268368126836
            </p>
          </div>
          <div class="tips">
            SHA加密网站得出来的结果与您拥有的证书内容完整性校验值一致，即没有问题
          </div>
          <div class="btnWrap">
            <el-button type="primary">去可信时间服务网站核验</el-button>
          </div>
        </template>

      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'LinkDialog',
  props: {
    // 当前激活导航
    viewRow: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      // 弹框是否显示
      visible: false,
    }
  },
  created () {
    this.visible = true
    this.init();
  },
  methods: {
    init () {
      console.log(this.viewIdx)
    },
    // 关闭弹框时触发的事件
    onClose () {
      this.$emit("close");
    },
  }
}
</script>

<style lang='scss' scoped>
.view {
  .topTittle {
    font-size: 16px;
    color: #1f2d3d;
    font-weight: bold;
    display: flex;
    align-items: center;
    img {
      width: 36px;
      height: 36px;
      margin-right: 8px;
    }
  }
  .content {
    background: #F2F8FF;
    padding: 16px 12px;
    margin: 16px 0 8px;
    font-weight: bold;
    white-space: normal;
    word-break: break-all;
    .gray {
      color: rgba(0, 18, 22, 0.5);
    }
    .value {
      color: #001216;
      margin-top: 4px;
    }
  }
  .tips {
    font-size: 12px;
    color: rgba(0, 18, 22, 0.5);
    margin: 8px 0;
  }
  .btnWrap {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
