<template>
  <div class="container certificateWrap">
    <div v-if="certificateInfo">
         <img src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/passIcon.png" class="sign">
    <div class="topBigTittle">
      <span class="line lineSmall"> </span>
      <span class="line lineMiddle"> </span>
      <span class="line lineBig"> </span>
      <span> 证书核验 </span>
      <span class="line lineBig"> </span>
      <span class="line lineMiddle"> </span>
      <span class="line lineSmall"> </span>
    </div>
    <div class="block">
      <div class="tittle"> <span>版权公示信息</span> </div>
      <ul>
        <li>
          <div class="smallTittle"> 作品名称 </div>
          <div class="value">
            {{certificateInfo.body.data.subject}}
          </div>
        </li>
        <li>
          <div class="smallTittle"> 著作权人 </div>
          <div class="value">
            {{certificateInfo.body.data.author}}
          </div>
        </li>
        <li>
          <div class="smallTittle"> 作者 </div>
          <div class="value">
            {{certificateInfo.body.data.author}}
          </div>
        </li>
        <li>
          <div class="smallTittle"> 区块链Hash地址 </div>
          <div class="value">
            {{certificateData.transactionHash}}
          </div>
        </li>
        <li>
          <div class="smallTittle"> 校验算法 </div>
          <div class="value">
            MD5
          </div>
        </li>
        <li>
          <div class="smallTittle"> 完整性校验值 </div>
          <div class="value">
            {{certificateInfo.body.data.md5Code}}
          </div>
        </li>
      </ul>
    </div>
    <div class="block">
      <div class="tittle">
        <span>作品内容</span>
        <span class="rightGray">作品文件总数 {{certificateInfo.body.data.fileMd5s.length}}</span>
      </div>
      <ul class="imgList clearfix">
        <li v-for="(file,index) in certificateInfo.body.files" :key="index">
          <div class="img" v-if="['jpg','jpeg','png','gif'].indexOf(file.fileType)>-1">
            <el-image 
              :src="file.downloadUrl"
              :preview-src-list="[file.downloadUrl]"
            >
            </el-image>
          </div>
          <div v-else>

          </div>
          <div class="name"> {{file.sourceName}}.{{file.fileType}}</div>
        </li>
      </ul>
    </div>
    <div class="block">
      <div class="tittle">
        <span> 证书核验</span>
      </div>
      <ul class="verificationList">
        <li
          v-for="(item,index) in list"
          :key="index"
          @click="openView(item)"
        >
          <img :src="item.img">
          <div class="content">
            <p class="tt"> {{item.tittle}} </p>
            <p class="words"> {{item.content}} </p>
          </div>
          <div class="arrow"> <i class="el-icon-arrow-right"></i> </div>
        </li>

      </ul>
    </div>
    </div>
     <div
        class="noData"
        v-else
      >
          <div class="inner">
             <img src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/noData.png">
               未查询到结果
          </div>
      </div>
    <link-dialog
      v-if="linkDialogVisible"
      :viewRow='viewRow'
      @close="onLinkDialogClose"
    ></link-dialog>
  </div>
</template>

<script>
import { queryCertificate } from "@/api/CertificateService";
import LinkDialog from './components/LinkDialog'
export default {
  name: 'certificate',
  components: { LinkDialog },
  data () {
    return {
      certificateCode: this.$route.params.certificateCode,
      certificateInfo: null,
      certificateData:null,
      linkDialogVisible: false,
      viewRow: null,
      srcList: [],
      list: [
        {
          img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/certificateIcon1.png',
          tittle: '证书内容核验',
          content: '核验结果与证书完整性校验值MD5比对',
          key: 'key1'
        },
        // {
        //   img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/certificateIcon2.png',
        //   tittle: 'XX互联网法院-XXX链核验',
        //   content: '在XX互联网法院官方验证平台，核验有效性',
        //   key: 'key2'
        // },
        {
          img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/certificateIcon3.png',
          tittle: '蚂蚁区块链核验',
          content: '在蚂蚁区块链公示平台查验公示Hash',
          key: 'key3'
        },
        // {
        //   img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/certificateIcon4.png',
        //   tittle: 'CA电子签名核验',
        //   content: '在XX官方验签平台进行签名验证',
        //   key: 'key4'
        // },
        // {
        //   img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/certificateIcon5.png',
        //   tittle: '授时时间核验',
        //   content: '在国家授时中心官网进行可信时间凭证验证',
        //   key: 'key5'
        // }
      ]
    }
  },
  created () {
    this.init();
  },
  methods: {
    init () {
      queryCertificate(this.certificateCode).then(({ data }) => {
        console.log("接口返回信息：",data);
        this.certificateData = data;
        this.certificateInfo = data.certificateInfo;
        this.srcList.push(data.certificateInfo.jpgUrl)
      });
    },
    openView (row) {
      this.viewRow = row
      this.linkDialogVisible = true
    },
    onLinkDialogClose () {
      this.linkDialogVisible = false
    }
  }
}
</script>

<style lang='scss' scoped>
.certificateWrap {
  background: #fff;
  padding: 24px 0;
  position: relative;
  .sign{
    position: absolute;
    top:36px;
    right:24px;
    width:148px;
  }
}
.topBigTittle {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 20px;
  color: #001216;
  margin-bottom: 24px;
  font-weight: bold;
  & > * {
    margin: 0 4px;
    display: block;
    line-height: 1em;
  }
  .line {
    width: 8px;
    border-radius: 3px;
  }
  .lineSmall {
    height: 12px;
    background: rgba(54, 207, 201, 0.2);
  }
  .lineMiddle {
    height: 16px;
    background: rgba(54, 207, 201, 0.6);
  }
  .lineBig {
    height: 20px;
    background: rgba(54, 207, 201, 1);
  }
}
.block {
  padding: 16px 24px;
  border-bottom: solid 8px #fafafa;
  .tittle {
    padding-bottom: 16px;
    color: #001216;
    font-weight: bold;
    font-size: 16px;
    border-bottom: solid 1px #eeeeee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .rightGray {
      font-size: 12px;
      color: rgba(0, 18, 22, 0.5);
    }
  }
  .smallTittle {
    color: rgba(0, 0, 0, 0.35);
    padding-left: 12px;
    position: relative;
    margin: 16px 0 8px;
  }
  .smallTittle::before {
    content: '';
    background: rgba(54, 207, 201, 1);
    position: absolute;
    width: 4px;
    height: 16px;
    top: 50%;
    left: 0;
    margin-top: -8px;
    border-radius: 2px;
  }
  .value {
    font-weight: bold;
    white-space: normal;
    word-break: break-all;
  }
  .imgList {
    li {
      margin-top: 16px;
      float: left;
      width: 32%;
      .name {
        font-weight: bold;
        margin-top: 4px;
        text-align: center;
      }
    }
    .img {
      width: 100%;
      height: 100px;
      background: #eee;
      /deep/ .el-image {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          display: block;
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;
        }
      }
    }
    & > li:nth-child(3n + 2),
    & > li:nth-child(3n + 3) {
      margin-left: 2%;
    }
  }
}
.verificationList {
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    img {
      width: 48px;
      height: 48px;
      margin-right: 8px;
    }
    .content {
      color: rgba(0, 18, 22, 0.45);
      font-size: 12px;
      flex: 1;
      .tt {
        color: rgba(0, 18, 22, 0.65);
        font-size: 14px;
        margin-bottom: 4px;
        font-weight: bold;
      }
    }
    .arrow {
      margin-left: 8px;
      color: rgba(0, 18, 22, 0.45);
      font-weight: bold;
      font-size: 18px;
    }
  }
}
.noData{
  height: calc(100vh - 243px);
  min-height: 360px;
}
</style>
